
























import Vue from "vue";
import { mapGetters } from "vuex";
import { TENANT_INFO_DETAILS } from "@/store/modules/candidates/constants";
import AssessmentHeader from "@/components/assessments/AssessmentHeader.vue";
import { AppRoutes } from "@/interfaces";

export default Vue.extend({
  name: "CandidateAssessmentComplete",
  components: { AssessmentHeader },
  data() {
    return {
      success_animation: require("@/assets/illustrations/onboarding-success.json")
    };
  },
  async created() {
    this.success_animation = JSON.stringify(this.success_animation);
  },
  computed: {
    ...mapGetters("candidate", {
      assessment_details: TENANT_INFO_DETAILS
    })
  },
  methods: {
    async assessment_report() {
      await this.$router.push(
        `${AppRoutes.Candidae.PERSONALITY_ASSESSMENT_REPORT}${this.assessment_details.id}`
      );
    }
  }
});
